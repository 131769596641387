import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Hero from "../components/hero-standard"
import Nav from "../components/nav"
import FooterMin from "../components/footer-min"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Book a demo" />
        <Nav />
        <div>
            <div>

              <div>
                <Hero h1="Book a demo" h2="Schedule your online demo" />

                <div className="article">
                  <div className='article-inner'>
                    <p style={{ textAlign: 'center', fontSize: '1.3em', color: '#3f6375', lineHeight: '150%' }}>
                      Submit your details below and one of our friendly team will get back in touch to arrange your online demo.
                    </p>
                    <form action="/demo-requested/" name="demo" method="POST" className="contact-form" data-netlify="true" data-netlify-honeypot="bot-field">
                      <p style={{ display: 'none' }}>
                        <label>Don't fill this out if you're human: <input name="bot-field" /></label>
                      </p>
                      <div className="contact-form__group">
                        <label>Name</label>
                        <input name="name" placeholder="Your name *" required className="contact-form__input" />
                      </div>
                      <div className="contact-form__group">
                        <label>Company</label>
                        <input name="company" placeholder="Company *" required className="contact-form__input" />
                      </div>
                      <div className="contact-form__group">
                        <label>Email</label>
                        <input name="email" placeholder="Email *" type="email" required className="contact-form__input" />
                      </div>
                      <div className="contact-form__group">
                        <label>Phone</label>
                        <input name="phone" placeholder="Phone *" required className="contact-form__input" />
                      </div>
                      <div className="contact-form__group">
                        <label>Package</label>
                        <select name="package" required className="contact-form__input">
                          <option disabled>Package *</option>
                          <option>Simple Package</option>
                          <option>Complete Package</option>
                          <option>Bus Open Data Package</option>
                          <option>Not sure</option>
                        </select>
                      </div>
                      <input type="hidden" name="form-name" value="demo" />
                      <p style={{ textAlign: 'center', paddingTop: '1em' }}>
                        <button className="btn" type="submit">Request demo <i className="fas fa-arrow-right"></i></button>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <FooterMin />
      </Layout>
    );
  }
};

export default BlogPage
